html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

.App {
    height: 100svh;
    width: 100svw;
    background-size: cover;
    background-image: url(kullarklang2.jpg);
    color: black;
    overflow: hidden;
    @media (aspect-ratio > 1.3) {
      background-image: url(kullarklang3.png);
      box-shadow: inset 0 0 0 100vmax rgba(0,0,0,.4);
      color: white;
      background-position-y: center;
    }
    @media (aspect-ratio < 0.7) {
 
    }

}

.link-style{
  text-decoration: none;
  cursor: pointer;
  margin-left: 2rem;
  margin-right: 2rem;
  font-size: calc(14px + 4vmin);
  font-family: "open-sans-aj";
  color: black;
  @media (aspect-ratio > 1.3) {
    color: white;
  }
}

.component{
  display: flex;
    align-items: center;
    font-size: calc(10px + 2vmin);
    text-align: center;
    padding-left: 0em;
    padding-right: 0em;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    height: 100%;
    padding-top: 2rem;

  @media (aspect-ratio < 1.5) {
    padding-left: 0em;
    padding-right: 0em;
  }
  @media (aspect-ratio < 1) {
    padding-left: 0em;
    padding-right: 0em;
  }
}

.info-box{
  color: black;
  font-family: 'romanT';
    background-color: white;
    box-shadow: inset 0 0 0 100vmax rgba(0,0,0,.05);
    opacity: 80%;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 2rem;
    padding-bottom: 3rem;
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: center;
    max-width: 30vmax;
    font-size: 0.9rem;
    overflow: scroll;
    min-height: 75%;

    @media (aspect-ratio < 1.3) {
      padding-left: 2rem;
      padding-right: 2rem;
      padding-top: 2rem;
      max-width: 65%;
      font-size: large;
    }
  @media (aspect-ratio < 0.6) {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 6rem;
    max-width: 85%;
    font-size: medium;
  }

}


.namn-font {
  font-family: "Corinthia", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 6rem;
  @media (aspect-ratio < 0.6) {
    font-size: 4rem;
  }
}

.datum-font {
  font-family: "Petit Formal Script", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.5rem;
  @media (aspect-ratio < 0.6) {
    font-size: 1.2rem;
  }
}
.ort-font {
  font-family: "EB Garamond", serif;
  font-size: 1.5rem;
  font-weight: 200;
  font-style: normal;
  @media (aspect-ratio < 0.6) {
    font-size: 1.2rem;
  }
}